/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('owl.carousel2/dist/owl.carousel.min')

$(document).ready(function(){

  $('#owl-one')
    .owlCarousel({
      items: 1,
      lazyLoad: true,
      autoplay: true,
      smartSpeed: 600,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
      video: true,
      navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 477.175 477.175" style="enable-background:new 0 0 477.175 477.175;" xml:space="preserve">\n' +
        '<g>\n' +
        '\t<path fill="#CECECE" d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225   c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"/>\n' +
        '</g>\n' +
        '</svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 129 129" enable-background="new 0 0 129 129">\n' +
        '  <g>\n' +
        '    <path fill="#CECECE" d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"/>\n' +
        '  </g>\n' +
        '</svg>'],
      nav:true,
      dots: true,
      rewind:true
    })

  $('#owl-two').owlCarousel({
    items: 1,
    center: true,
    loop: true,
    margin: 10,
    // nav: true,
    dots: false,
    autoplay: true,
    lazyLoad: true,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    rewind: true,
    // navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 477.175 477.175" style="enable-background:new 0 0 477.175 477.175;" xml:space="preserve">\n' +
    //   '<g>\n' +
    //   '\t<path fill="#CECECE" d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225   c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"/>\n' +
    //   '</g>\n' +
    //   '</svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 129 129" enable-background="new 0 0 129 129">\n' +
    //   '  <g>\n' +
    //   '    <path fill="#CECECE" d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"/>\n' +
    //   '  </g>\n' +
    //   '</svg>'],
  });


  let _home = function () {

    let sendNewsletterForm = function () {
      let input = $('#newsletter-form').find('input.c-newsletter__input');

      if(input.val() == '') {
        Swal.fire(
          'Por favor, preencha o campo de E-mail!',
          'Campo obrigatório.',
          'error'
        )

        return false;
      }
      $('#newsletter-form').submit();
    }

    return {
      sendNewsletterForm: sendNewsletterForm
    }
  }

  Home = _home()

  $('#booklet').modal('show')

});
